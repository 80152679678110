export const legacyColors = {
  "--color-slate-800": "#1e293b",
  "--color-gray-100": "#f3f4f6",
  "--color-gray-200": "#e5e7eb",
  "--color-gray-300": "#d1d5db",
  "--color-gray-400": "#9ca3af",
  "--color-gray-500": "#6b7280",
  "--color-gray-900": "#111827",
  "--color-red-200": "#fecaca",
  "--color-red-300": "#fca5a5",
  "--color-red-400": "#f87171",
  "--color-red-500": "#ef4444",
  "--color-yellow-300": "#fde047",
  "--color-yellow-400": "#facc15",
  "--color-green-200": "#bbf7d0",
  "--color-green-300": "#86efac",
  "--color-green-400": "#4ade80",
  "--color-blue-100": "#dbeafe",
  "--color-blue-200": "#bfdbfe",
  "--color-blue-300": "#93c5fd",
  "--color-blue-400": "#60a5fa",
  "--color-blue-500": "#3b82f6",
  "--color-purple-200": "#e9d5ff",
  "--color-purple-300": "#d8b4fe",
  "--color-purple-400": "#c084fc",
} as const;

export const newColorsLight = {
  "--color-bg1": "255 250 239",
  "--color-bg2": "239 238 234",
  "--color-borderBg1": "239 238 234",
  "--color-text": "0 0 0",
  "--color-textInverted": "239 238 234",
  "--color-primary": "51 50 150",
  "--color-borderPrimary": "38 37 111",
  "--color-textPrimary": "239 238 234",
  "--color-secondary": "116 218 160",
  "--color-borderSecondary": "104 202 146",
  "--color-textSecondary": "0 0 0",
  "--color-highlight": "248 68 57",
} as const;

export const newColorsDark = {
  "--color-bg1": "56 49 46",
  "--color-bg2": "42 37 34",
  "--color-borderBg1": "42 37 34",
  "--color-text": "251 249 244",
  "--color-textInverted": "0 0 0",
  "--color-primary": "25 109 206",
  "--color-borderPrimary": "16 69 137",
  "--color-textPrimary": "0 0 0",
  "--color-secondary": "0 92 73",
  "--color-borderSecondary": "0 71 57",
  "--color-textSecondary": "251 249 244",
  "--color-highlight": "213 16 98",
} satisfies Record<keyof typeof newColorsLight, string>;

export const newColorsLegacy = {
  "--color-bg1": "255 255 255",
  "--color-bg2": "229 231 235",
  "--color-borderBg1": "199 199 199",
  "--color-text": "0 0 0",
  "--color-textInverted": "255 255 255",
  "--color-secondary": "134 239 172",
  "--color-textSecondary": "0 0 0",
  "--color-borderSecondary": "110 231 183",
  "--color-primary": "191 219 254",
  "--color-textPrimary": "0 0 0",
  "--color-borderPrimary": "147 197 253",
  "--color-highlight": "213 16 98",
} satisfies Record<keyof typeof newColorsLight, string>;
