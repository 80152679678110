"use client";

import { motion } from "motion/react";
import { useEffect, useState } from "react";

export default function Background() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className="-z-10">
      <motion.div
        style={{
          left: "15%",
          top: "0%",
          height: "200px",
          width: "200px",
        }}
        animate={{
          transform: `translate(${-mousePosition.x / 5}px, ${-mousePosition.y / 5}px)`,
        }}
        transition={{
          duration: 0.3,
        }}
        className="absolute rounded-full bg-gradient-to-r from-secondary to-primary"
      />
      <motion.div
        style={{
          left: "80%",
          top: "15%",
          height: "300px",
          width: "300px",
        }}
        animate={{
          transform: `translate(${mousePosition.y / 8}px, ${-mousePosition.x / 4}px)`,
        }}
        transition={{
          duration: 0.3,
        }}
        className="absolute rounded-full bg-secondary"
      />
      <motion.div
        style={{
          left: "10%",
          top: "8%",
          height: "600px",
          width: "600px",
        }}
        animate={{
          transform: `translate(${mousePosition.x / 6}px, ${mousePosition.y / 8}px)`,
        }}
        transition={{
          duration: 0.3,
        }}
        className="absolute rounded-full bg-gradient-to-r from-secondary to-primary"
      />
      <div className="fixed bottom-0 left-0 right-0 top-0  backdrop-blur-[100px]"></div>
    </div>
  );
}
