"use client";

import clsx from "clsx";
import { Pressable, View } from "react-native";
import Loader from "./Loader";
import { useEffect, useState } from "react";
import Icon from "../icons";

import { GestureResponderEvent, StyleProp, ViewStyle } from "react-native";
import { IconNames } from "../icons/types";
import {
  SemanticColors,
  activeClasses,
  bgBorderClasses,
} from "../utils/colors";
import Text, { textColorClasses } from "./Text";
import Modal from "./Modal";
import { TextClassNameContext } from "./textUtils";

const textVariantClasses = {
  xs: "text-xs",
  s: "text-base",
  m: "text-lg font-light",
  l: "text-lg uppercase tracking-widest font-light",
} as const;

const iconSizeMap = {
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
} as const;

const variantClasses = {
  xs: "h-[22px] rounded-[4px] px-[6px] py-[2px]",
  s: "h-[28px] rounded-[6px] px-[8px]",
  m: "h-[40px] rounded-[8px] px-[16px]",
  l: "h-[48px] rounded-[2px] px-[22px]",
} as const;

export default function Button({
  onPress,
  onClick,
  children,
  variant = "m",
  color = "primary",
  isLoading = false,
  className,
  width,
  disabled = false,
  error,
  text,
  icon,
  style,
  testID,
}: {
  onPress?: () => void;
  onClick?: (event: GestureResponderEvent) => void;
  isLoading?: boolean;
  error?: string;
  variant?: "xs" | "s" | "m" | "l";
  color?: SemanticColors;
  width?: number;
  disabled?: boolean;
  children?: React.ReactNode;
  text?: string | null;
  icon?: IconNames;
  style?: StyleProp<ViewStyle>;
  className?: string;
  testID?: string;
}) {
  const [hideError, setHideError] = useState(false);
  useEffect(() => {
    if (error) {
      setHideError(false);
    }
  }, [error]);

  return (
    <>
      <Pressable
        role="button"
        disabled={disabled}
        onPress={(e) => {
          onPress?.();
          onClick?.(e);
        }}
        className={clsx(
          className,
          "group flex flex-row items-center justify-center transition-all duration-300",
          bgBorderClasses[color],
          activeClasses[color],
          variantClasses[variant],
          {
            "border-2": variant === "m" || variant === "l",
          },
          {
            "cursor-not-allowed opacity-30": disabled,
          },
        )}
        style={[{ width, gap: 4 }, style]}
        testID={testID}
      >
        <TextClassNameContext.Provider value={textColorClasses[color]}>
          {/* <View
        // border={variant === "m" || variant === "l"}
        // style={[styles.background, variantStyles[variant]]}
        className={clsx(
          "pointer-events-none transition-colors",
          bgBorderClasses[color],
          {
            "border-2": variant === "m" || variant === "l",
          },
        )}
      /> */}
          {isLoading && (
            <View className="absolute">
              <Loader />
            </View>
          )}
          {text && (
            <Text
              className={clsx(
                "relative flex flex-row items-center justify-center whitespace-nowrap text-center",
                textVariantClasses[variant],
                {
                  "opacity-0": isLoading,
                  "opacity-100": !isLoading,
                },
              )}
            >
              {text}
            </Text>
          )}
          {icon && <Icon icon={icon} size={iconSizeMap[variant]} />}
          {children}
        </TextClassNameContext.Provider>
      </Pressable>
      <Modal
        name="buttonErrorModal"
        isVisible={!hideError && !!error}
        onBackdropPress={() => setHideError(true)}
      >
        <View className="rounded-lg border-highlight bg-bg1 p-4">
          <Text>{error}</Text>
        </View>
      </Modal>
    </>
  );
}
