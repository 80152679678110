import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

/* eslint-disable no-process-env */
export const apiEnv = createEnv({
  server: {
    // Config
    STAGE: z.enum(["development", "production", "test"]),
    // // DB
    EXPO_ACCESS_TOKEN: z.string().min(1),
    // DATABASE_HOST: z.string().min(1),
    // DATABASE_USER: z.string().min(1),
    // DATABASE_PASSWORD: z.string().min(1),
    // DATABASE_NAME: z.string().min(1),
    // Upstash
    UPSTASH_REDIS_REST_URL: z.string().min(1),
    UPSTASH_REDIS_REST_TOKEN: z.string().min(1),
  },
  runtimeEnv: {
    STAGE: process.env.STAGE,
    UPSTASH_REDIS_REST_URL: process.env.UPSTASH_REDIS_REST_URL,
    UPSTASH_REDIS_REST_TOKEN: process.env.UPSTASH_REDIS_REST_TOKEN,
    EXPO_ACCESS_TOKEN: process.env.EXPO_ACCESS_TOKEN,
  },
  skipValidation:
    !!process.env.CI ||
    !!process.env.SKIP_ENV_VALIDATION ||
    process.env.npm_lifecycle_event === "lint",
});
