"use client";

import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import { Text as RNText, Platform } from "react-native";

import { SemanticColors } from "../utils/colors";

import { TextClassNameContext, useParentClassName } from "./textUtils";

export { TextClassNameContext };

type CustomTextProps = {
  contextClassName?: string;
};

export const textColorClasses = {
  black: "text-white",
  white: "text-black",
  // green: "text-black",
  // purple: "text-black",
  // blue: "text-black",
  // red: "text-black",
  // gray: "text-black",
  primary: "text-textPrimary",
  secondary: "text-textSecondary",
  text: "text-textInverted",
  textInverted: "text-text",
  bg1: "text-text",
  highlight: "text-highlight",
  textPrimary: "text-primary",
} satisfies Record<SemanticColors, string>;

const fontFamily = Platform.OS === "web" ? "" : "InterVariable";

const baseColorClass = Platform.OS === "web" ? "text-inherit" : "text-text";
const baseTextClasses = `text-base ${baseColorClass}`;

export default function Text({
  className,
  // color,
  contextClassName,
  // weight,
  ...props
}: ComponentProps<typeof RNText> & CustomTextProps) {
  const parentClassName = useParentClassName();

  if (contextClassName) {
    return (
      <TextClassNameContext.Provider
        value={twMerge(parentClassName, contextClassName)}
      >
        <RNText
          style={{ fontFamily }}
          className={twMerge(
            baseTextClasses,
            parentClassName,
            contextClassName,
            className,
          )}
          {...props}
        />
      </TextClassNameContext.Provider>
    );
  }

  return (
    <RNText
      style={{ fontFamily }}
      className={twMerge(baseTextClasses, parentClassName, className)}
      {...props}
    />
  );
}
