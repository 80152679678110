"use client";

import { useEffect, useState } from "react";
import { useSpring, motion } from "motion/react";

export default function StaticThemeSwitcher(props: {
  theme: string | undefined;
}) {
  const [theme, setTheme] = useState(props.theme);

  const swapTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add("dark");
    } else {
      setTheme("light");
      document.documentElement.classList.add("light");
      document.documentElement.classList.remove("dark");
    }
  };

  return (
    <div onClick={swapTheme}>
      <SVG isDarkMode={theme === "dark"} />
    </div>
  );
}

const properties = {
  sun: {
    r: 9,
    // transform: "rotate(40deg)",
    rotate: 40,
    cx: 12,
    cy: 4,
    opacity: 0,
  },
  moon: {
    r: 5,
    // transform: "rotate(90deg)",
    rotate: 90,
    cx: 30,
    cy: 0,
    opacity: 1,
  },
} as const;

function SVG({ isDarkMode }: { isDarkMode: boolean }) {
  const props = properties[isDarkMode ? "moon" : "sun"];

  const rotate = useSpring(props.rotate);
  const r = useSpring(props.r);
  const cx = useSpring(props.cx);
  const cy = useSpring(props.cy);
  const opacity = useSpring(props.opacity);

  useEffect(() => {
    rotate.set(props.rotate);
    r.set(props.r);
    cx.set(props.cx);
    cy.set(props.cy);
    opacity.set(props.opacity);
  }, [isDarkMode, rotate, r, cx, cy, opacity, props]);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      //   transform={`rotate(${transform}deg)`}
      //   animate={{ rotate }}
      style={{
        cursor: "pointer",
        rotate,
      }}
    >
      <mask id="myMask2">
        <rect x="0" y="0" width="100%" height="100%" fill="white" />
        <motion.circle cx={cx} cy={cy} r={r} fill="black" />
      </mask>

      <motion.circle cx="12" cy="12" r={r} fill="black" mask="url(#myMask2)" />
      <motion.g stroke="currentColor" style={{ opacity }}>
        <line x1="12" y1="1" x2="12" y2="3" />
        <line x1="12" y1="21" x2="12" y2="23" />
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
        <line x1="1" y1="12" x2="3" y2="12" />
        <line x1="21" y1="12" x2="23" y2="12" />
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
      </motion.g>
    </motion.svg>
  );
}
