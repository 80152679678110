"use client";

import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";

export default function LinkColumn(props: {
  title: { text: string; href: string };
  links: { text: string; href: string }[];
}) {
  const pathname = usePathname();
  return (
    <div className="flex flex-col">
      <Link href={props.title.href}>
        <div className="font-semibold text-text/50 hover:underline">
          {props.title.text}
        </div>
      </Link>
      {props.links.map((link) => (
        <Link
          key={link.href}
          href={link.href}
          className={clsx(
            "mr-auto flex items-center decoration-primary underline-offset-2 hover:underline",
            {
              underline: link.href === pathname,
            },
          )}
        >
          {/* <span className="mr-2 text-2xl">🌱</span> */}

          <span className="text font-light text-text decoration-text transition-colors hover:text-purple-300">
            {link.text}
          </span>
        </Link>
      ))}
    </div>
  );
}
