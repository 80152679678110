import { useThemeContextValues } from ".";
import { getObjectEntries } from "@30p/api/src/utils";
import {
  legacyColors,
  newColorsLight,
  newColorsDark,
  newColorsLegacy,
} from "./colors";

export type ThemesVariant = "light" | "legacy" | "dark" | "debug";

type Color = `${number} ${number} ${number}`;

type Shape = Record<
  keyof typeof legacyColors | keyof typeof newColorsLight,
  string
>;

// const errorPink: Color = "#E619C4";
const errorPink: Color = "230 25 196";
// const OKGreen: Color = "0 255 0";

const debugColors = getObjectEntries(newColorsLight).reduce(
  (acc, [key]) => {
    acc[key] = errorPink;
    return acc;
  },
  {} as Record<keyof typeof newColorsLight, Color>,
);

export const Themes = {
  legacy: { ...legacyColors, ...newColorsLegacy },
  light: {
    // legacy
    "--color-slate-800": errorPink,
    "--color-gray-100": errorPink,
    "--color-gray-200": "#FFFAEF",
    "--color-gray-300": "#EFEEEA",
    "--color-gray-400": errorPink,
    "--color-gray-500": errorPink,
    "--color-gray-900": "#111827",
    "--color-red-200": errorPink,
    "--color-red-300": "#fca5a5",
    "--color-red-400": errorPink,
    "--color-red-500": errorPink,
    "--color-yellow-300": "#fde047",
    "--color-yellow-400": "#facc15",
    "--color-green-200": errorPink,
    "--color-green-300": "#74DAA0",
    "--color-green-400": "#68CA92",
    "--color-blue-100": errorPink,
    "--color-blue-200": "#333296",
    "--color-blue-300": "#26256F",
    "--color-blue-400": errorPink,
    "--color-blue-500": errorPink,
    "--color-purple-200": errorPink,
    "--color-purple-300": errorPink,
    "--color-purple-400": errorPink,
    // new
    ...newColorsLight,
  } satisfies Shape,
  dark: {
    "--color-slate-800": "#1e293b",
    "--color-gray-100": "#f3f4f6",
    "--color-gray-200": "#e5e7eb",
    "--color-gray-300": "#d1d5db",
    "--color-gray-400": "#9ca3af",
    "--color-gray-500": "#6b7280",
    "--color-gray-900": "#111827",
    "--color-red-200": "#fecaca",
    "--color-red-300": "#fca5a5",
    "--color-red-400": "#f87171",
    "--color-red-500": "#ef4444",
    "--color-yellow-300": "#fde047",
    "--color-yellow-400": "#facc15",
    "--color-green-200": "#bbf7d0",
    "--color-green-300": "#86efac",
    "--color-green-400": "#4ade80",
    "--color-blue-100": "#dbeafe",
    "--color-blue-200": "#bfdbfe",
    "--color-blue-300": "#93c5fd",
    "--color-blue-400": "#60a5fa",
    "--color-blue-500": "#3b82f6",
    "--color-purple-200": "#e9d5ff",
    "--color-purple-300": "#d8b4fe",
    "--color-purple-400": "#c084fc",
    // new colors
    ...newColorsDark,
  } satisfies Shape,
  debug: {
    "--color-slate-800": errorPink,
    "--color-gray-100": errorPink,
    "--color-gray-200": errorPink,
    "--color-gray-300": errorPink,
    "--color-gray-400": errorPink,
    "--color-gray-500": errorPink,
    "--color-gray-900": errorPink,
    "--color-red-200": errorPink,
    "--color-red-300": errorPink,
    "--color-red-400": errorPink,
    "--color-red-500": errorPink,
    "--color-yellow-300": errorPink,
    "--color-yellow-400": errorPink,
    "--color-green-200": errorPink,
    "--color-green-300": errorPink,
    "--color-green-400": errorPink,
    "--color-blue-100": errorPink,
    "--color-blue-200": errorPink,
    "--color-blue-300": errorPink,
    "--color-blue-400": errorPink,
    "--color-blue-500": errorPink,
    "--color-purple-200": errorPink,
    "--color-purple-300": errorPink,
    "--color-purple-400": errorPink,
    // new colors
    ...debugColors,
  } satisfies Shape,
};

function getColors(theme: ThemesVariant) {
  if (theme === "light") {
    return newColorsLight;
  }
  if (theme === "dark") {
    return newColorsDark;
  }
  if (theme === "legacy") {
    return newColorsLegacy;
  }
  if (theme === "debug") {
    return debugColors;
  }

  return debugColors;
}

export const useThemeColors = () => {
  const { theme } = useThemeContextValues();

  const colors = getColors(theme);

  return (name: keyof typeof newColorsLight, alpha?: number) =>
    toRgba(colors[name], alpha);
};

export const toRgba = (color: string, alpha?: number) =>
  alpha
    ? `rgba(${color.split(" ").join(",")}, ${alpha})`
    : `rgb(${color.split(" ").join(",")})`;
