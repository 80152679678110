"use client";

import { useAuth } from "@clerk/nextjs";
import Button from "@30p/ui/atoms/Button";
import Link from "next/link";
import { useEffect, useState } from "react";

export default function TopNavSignIn() {
  const { isSignedIn, isLoaded } = useAuth();
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setEmail(email);
    }
  }, []);

  if (!isLoaded) return <Button disabled width={100} isLoading />;

  if (isSignedIn) {
    return (
      <Link href="/dashboard">
        <Button color="primary" text="Dashboard" />
      </Link>
    );
  }

  if (email) {
    return (
      <Link href="/sign-in">
        <Button color="primary" text="Sign in" testID="sign-in-button" />
      </Link>
    );
  }

  return (
    <div className="flex items-center gap-4">
      <Link href="/sign-up">
        <Button
          testID="get-started-button"
          color="primary"
          text="Get Started"
        />
      </Link>
    </div>
  );
}
