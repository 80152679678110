"use client";

import { useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import clsx from "clsx";
import Icon from "@30p/ui/icons";
import Button from "@30p/ui/atoms/Button";

export default function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="flex flex-1 items-center justify-between transition-all md:hidden">
        {isOpen ? (
          <Icon icon="Close" size={40} onPress={() => setIsOpen(false)} />
        ) : (
          <Icon icon="Menu" size={40} onPress={() => setIsOpen(true)} />
        )}
        <Link href="/sign-in">
          <Button color="primary" text="Sign in" />
        </Link>
      </div>

      <div
        className={clsx(
          "fixed left-0 right-0 top-20 overflow-hidden bg-bg1 transition-all",
          {
            "h-[100vh]": isOpen,
            "h-[0px] ": !isOpen,
          },
        )}
      >
        <div className="flex flex-col gap-4 px-4 py-2 text-2xl">
          <NavLink light href="/">
            Home
          </NavLink>
          <LinkColumn
            title={{ text: "Contact", href: "/contact" }}
            links={[
              {
                text: "Email",
                href: "/contact",
              },
              {
                text: "Discord",
                href: "https://discord.gg/rn7qBkRfgB",
              },
            ]}
          />
          <LinkColumn
            title={{ text: "Legal", href: "/legal" }}
            links={[
              {
                text: "Privacy Policy",
                href: "/legal/privacy-policy",
              },
              {
                text: "Terms of Service",
                href: "/legal/terms-of-service",
              },
            ]}
          />
          <LinkColumn
            title={{ text: "Resources", href: "/resources" }}
            links={[
              {
                text: "FAQs",
                href: "/resources/frequently-asked-questions",
              },
              {
                text: "Developer Blog",
                href: "/resources/developer-blog",
              },
              {
                text: "Roadmap",
                href: "/resources/roadmap",
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}

function LinkColumn(props: {
  title: { text: string; href: string };
  links: { text: string; href: string }[];
}) {
  const pathname = usePathname();
  return (
    <div className="flex flex-col">
      <Link href={props.title.href}>
        <div className="font-light text-gray-400">{props.title.text}</div>
      </Link>
      {props.links.map((link) => (
        <Link
          key={link.href}
          href={link.href}
          className={clsx(
            "mr-auto flex items-center decoration-green-300 underline-offset-2",
            {
              underline: link.href === pathname,
            },
          )}
        >
          {/* <span className="mr-2 text-2xl">🌱</span> */}
          <span className={clsx("text font-light decoration-white", {})}>
            {link.text}
          </span>
        </Link>
      ))}
    </div>
  );
}

export function NavLink(props: {
  href: string;
  children: React.ReactNode;
  underline?: boolean;
  light?: boolean;
}) {
  // const pathname = usePathname();
  return (
    <Link href={props.href} className="mr-auto flex items-center">
      {/* <span className="mr-2 text-2xl">🌱</span> */}
      <span
        className={clsx(
          "text decoration-primary underline-offset-2 hover:decoration-textInverted",
          {
            // underline: props.underline || props.href === pathname,
            "font-light": props.light,
            "font-medium": !props.light,
          },
        )}
      >
        {props.children}
      </span>
    </Link>
  );
}
