"use client";

import { useCallback, useEffect } from "react";
import { Platform, View, ViewProps } from "react-native";
import { Themes, ThemesVariant } from "./theme-config";
import clsx from "clsx";
import * as Storage from "../../utils/storage";

import { useColorScheme } from "react-native";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { vars } from "nativewind";

const themeProviderValuesAtom = atom<ThemesVariant | null>(null);

export function Theme(props: ViewProps) {
  const colorScheme = useColorScheme();

  const [theme, setTheme] = useAtom(themeProviderValuesAtom);

  useEffect(() => {
    Storage.get("theme").then((_theme) => {
      if (!_theme) return;
      const parsedTheme = JSON.parse(_theme) as ThemesVariant;
      if (
        parsedTheme === "dark" ||
        parsedTheme === "light" ||
        parsedTheme === "debug" ||
        parsedTheme === "legacy"
      )
        setTheme(parsedTheme);
    });

    setTheme(colorScheme === "dark" ? "dark" : "light");
  }, [colorScheme, setTheme]);

  if (theme === null) return null;

  return (
    <View
      style={vars(Themes[theme])}
      className={clsx("flex flex-1 bg-bg1", props.className)}
    >
      {props.children}
    </View>
  );
}

export function useThemeContextValues() {
  const theme = useAtomValue(themeProviderValuesAtom) ?? "light";
  return { theme };
}

export function useThemeContextActions() {
  const setTheme = useSetAtom(themeProviderValuesAtom);

  const handleThemeSwitch = useCallback(
    (newTheme: ThemesVariant) => {
      setTheme(newTheme);
      Storage.set("theme", newTheme);
      if (Platform.OS === "web") {
        document.cookie = `theme=${newTheme}; path=/`;
        document.documentElement.classList.remove(
          "light",
          "dark",
          "debug",
          "legacy",
        );
        document.documentElement.classList.add(newTheme);
      }
    },
    [setTheme],
  );

  return {
    handleThemeSwitch,
  };
}
